import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import PageHeader from "../../components/pageHeader"
import SEO from "../../components/seo"

import ScrollAnimation from 'react-animate-on-scroll';

import ImageCoaching from '../../components/images/imageCoaching.js'
import ImageDevelopement from '../../components/images/imageDevelopement.js'
import ImageClimat from '../../components/images/imageClimat.js'
import ImageChange from '../../components/images/imageChange.js'
import ImageConflit from '../../components/images/imageConflit.js'
import ImageLeft6 from '../../components/images/imageLeft6.js'

const Equipes = () => (
  <Layout>
    <SEO title="Développement des compétences pour les équipes | Développement | Gestion Mieux-Être" />
    <PageHeader
        header="Développement des compétences pour les équipes et collaborateurs"
        text="Nos formules de développement visent des résultats novateurs et durables. Nous soutenons les individus et organisations afin qu’ils relèvent avec brio les défis.  Nous offrons un éventail de solutions de développement simples et adaptées.  "
        quote="Le danger, ce n’est pas ce qu’on ignore, c’est ce que l’on tient pour certain et qui ne l’est pas<span>- Mark Twain</span>"
        background="developpement" />
    <div className="main-wrapper pad-page tcenter">
        <ScrollAnimation
            delay="0"
            animateIn="fadeIn">

        <p class="service-presentation2">Voici ici quelques exemples d'activités de développement pour les équipes et les collaborateurs (Formation – Conférences):</p>
        <div className="flex-container pad2">
            <div className="half"><ImageLeft6 /></div>
            <div className="half">
                <ul className="success">
                    <li>Tirer profit de vos différences</li>
                    <li>Les vertiges et défis du changement</li>
                    <li>Ne laissez pas le trac vous ralentir </li>
                    <li>Le stress vous stresse? </li>
                </ul>
            </div>

        </div>
        <p class="service-presentation2">Le perfectionnement n'est-il pas une occasion privilégiée afin d'envisager vos défis dans de nouvelles perspectives?  </p>
        <Link className="button-link" to="/nous-joindre">Nous joindre</Link>
        </ScrollAnimation>

    </div>
  </Layout>
)

export default Equipes
